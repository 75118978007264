<template>
  <div class="dataUploadManage padding-lr-20 padding-top-20">
        <div class="column" :style="{'margin-bottom':0}" v-loading="pageload">
          <el-tabs v-model="activeKey" @tab-click="tabclick" >
            <el-tab-pane label="数据管理" name="manage"></el-tab-pane>
            <el-tab-pane label="数据更新" name="update"></el-tab-pane>
          </el-tabs>
          <!-- 数据管理 -->
          <el-row  v-show="activeKey=='manage'" type="flex" :gutter="20"  :style="{'min-height':($root.$data.docHeight-189)+'px'}">
            
            <el-col :span="18">
              <div class="flex-row justify-between align-items-end padding-tb-15 border-bottom margin-bottom-10">
                <div class="flex-row  align-items-center ">
                  <div class="margin-right-10">
                    <el-input v-model="keywords" placeholder="请输入关键字"  @change="searchdata(selectedfile)" size="mini" suffix-icon="el-icon-search"></el-input>
                  </div>
                  <div>
                      <el-select v-model="selectedfile" placeholder="请选择" size="mini" @change="searchdata">
                        <el-option
                         
                          key="0"
                          label="全部上传文件"
                          value="0"
                        >
                        </el-option>
                        <el-option
                          v-for="item in filenameList"
                          :key="item"
                          :label="item"
                          :value="item"
                          >
                        </el-option>
                      </el-select>
                  </div>
                </div>
                
                <div class="flex-row align-items-center ">
                  <div class="font-size-12 color-sub margin-right-10 margin-left-20">请为数据设置标识，以便于分类和使用</div>
                  <el-button  @click="opentagDialog" size="mini" >设置标识<i class="el-icon-s-tools el-icon--right"></i></el-button>
                </div>
                
              </div>
              <!-- highlight-current-row
              highlight-selection-row
              @current-change="handleCurrentChange" -->
              <el-table size="medium" :data="tableData" 
              ref="singleTable"
              @selection-change="changeselectedtable"
              height="680"
              :key="keyNum"
              :highlight-current-row=true
              @row-click="handleCurrentChange"
              >
                <el-table-column
                type="selection"
                width="55">
              </el-table-column>
               <el-table-column
                  prop="fieldName"
                  label="数据名称"
                 >
                </el-table-column>
                <el-table-column
                  prop="unit"
                  label="单位"  width="90">
                </el-table-column>
                <el-table-column
                  prop="modelFreq"
                  label="更新频率"  width="90">
                </el-table-column>
               
                <el-table-column 
                  prop="tagName"
                  label="数据类型">
                </el-table-column>
                <el-table-column
                   width="120"
                  label="关联产品">
                  <template v-slot="scope">
                      <div class="text-overflow-ellipsis" :title="scope.row.productName">{{scope.row.productName}}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="fileName"
                  label="上传文件">
                </el-table-column>
                <el-table-column
                  label="操作"  width="110">
                  <template v-slot="scope" >
                        <span @click="deleteData(scope.row.fieldId)" class="link-text margin-right-10">删除</span>
                        <span @click.stop="handleCurrentChange(scope.row)"  class="link-text">查看</span>
                    </template>
                </el-table-column>
              
              </el-table>
            </el-col>
            <!-- 数据预览 -->
            <el-col :span="6">
            
                 <div class="font-size-14 padding-top-15 margin-bottom-25 line-height-larger ">数据详情</div>
            
              <el-table
                size="medium"
                :data="tableDatadetail"
                height="680"
                style="width: 100%">
                <el-table-column
                  prop="datadate"
                  label="日期"
                 >
                </el-table-column>
                <el-table-column
                  prop="value"
                  label="数值"
                 >
                </el-table-column>
              </el-table>
            </el-col>
            <!-- 数据预览结束 -->
          </el-row>
           <!-- 数据管理结束 -->
          <!-- 数据更新 -->
          <el-row v-show="activeKey=='update'" type="flex" :gutter="20" :style="{'min-height':($root.$data.docHeight-189)+'px'}" class="">
            <el-col :span="6">
              <div class="flex-auto border-right padding-right-30  padding-top-15">
                <div class="flex-row justify-between align-items-start padding-bottom-10 border-bottom">
                  <el-input size="mini" v-model="dataSetByUpdatakeyWord"  placeholder="请输入关键字"  @change="searchupdate(selectedfile)"  suffix-icon="el-icon-search">
                   
                  </el-input>
                </div>
                <div class="flex-column">
                  <div style="cursor: pointer" v-for="(item,i) in dataSetByUpdata" :key="i" @click.stop="getDataSetByUpdata(item,i)"
                       :class="(activeByUpdata==i ? 'activeClass  ' :'')+
                       'flex-row justify-between align-items-start padding-top-10 padding-bottom-10 padding-right-10 padding-left-10 border-bottom'">
                   
                      <div>{{item.filename}} </div>
                      <!-- <el-link @click="downloadData"  target="_blank">下载数据</el-link> -->
                      <span  @click="downloadData"   class="link-text">下载数据</span>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="18">
              <div class="padding-top-15">
                <upload-for-excel ref="fixUploadDrag" :isUpdata="true" uploadBtnText="更新数据" :fileId="dataSetByUpdata.length>0?dataSetByUpdata[activeByUpdata].id:''" ></upload-for-excel>
              </div>
             </el-col>
          </el-row>
        
        </div>
          <!-- 弹窗-标签管理 -->
          
          <el-dialog
              title="属性设置"
              :visible.sync="tagdialog"
              :append-to-body="true"
              width="880px"
              class= "tagmanage"
              custom-class="custom-dialog"

            >
            <div class=" font-size-12 color-sub line-height-larger  padding-bottom-10">用于自定义模型/因子管理时对数据的识别</div>
              <div class="flex-row  border-top border-bottom padding-top-15">
                <div class="leftbox border-right padding-right-15">
                  <div  class="font-size-14">选择数据频率</div>
                   <div>
                    
                    <ul class="padding-lr-5 margin-top-10 border-top padding-top-10" style="height: 180px;">
                      <el-radio-group v-model="freqscheck">
                      <li v-for="(fitme,i) in freqlist" :key="i" class="padding-tb-10">
                        <el-radio :label="fitme.value">{{fitme.title}}</el-radio>
                      </li>
                    </el-radio-group>
                    </ul>
                    <div  class="font-size-14">设置数据单位</div>
                    <div class=" border-top padding-top-10 margin-top-10"><el-input v-model="dataunit" placeholder="请输入单位" size="mini" maxlength="20"  show-word-limit></el-input></div>
                  </div>
                  
                </div>
                <div class="leftbox border-right  padding-lr-15">
                  <div  class="font-size-14">选择数据类型</div>
                  <div class="flex-row margin-top-15 ">
                    <el-input v-model="newtag"  size="mini" class="margin-right-10" placeholder="请输入数据类型"   maxlength="10" show-word-limit></el-input>
                    <el-button style="margin-left: 5px"  size="mini" @click="addTag">添加类型</el-button>
                  </div>
                  <div>
                    <ul class="padding-lr-5 margin-top-10 border-top">
                      <li v-for="(pitme,i) in tagsList" :key="i" class="flex-row  justify-between line-height-large border-bottom padding-5"  :class="selectedTag==pitme.tagid?'activeClass':''"  @click="selectedTag=pitme.tagid">
                        <div>{{pitme.tagname}}</div>
                        <el-link  @click="delOneTag(pitme.tagid)">删除</el-link>
                      </li>
                    </ul>
                  </div>
                </div>
               
                <div class="leftbox  padding-lr-15">
                  
                  <div  class="font-size-14">选择关联产品</div>
                  <div class="flex-row margin-top-15 ">
                    <el-input v-model="newproduct" size="mini" placeholder="请输入关联产品"  maxlength="10" show-word-limit></el-input>
                    <el-button style="margin-left: 5px" size="mini" @click="addproduct">添加关联产品</el-button>
                  </div>
                  <div>
                    <ul  class=" padding-lr-5 margin-top-10 border-top">
                      <el-checkbox-group v-model="productcheckList">
                      <li v-for="(pitme,i) in productsList" :key="i" class="flex-row  justify-between line-height-large border-bottom padding-5">
                        <div> <el-checkbox :key="pitme.productId" :label="pitme.productId">{{pitme.productName}}</el-checkbox></div>
                        <el-link v-if="!pitme.sciProductId"  @click="delproduct(pitme.productId)">删除</el-link>                         
                      </li>
                    </el-checkbox-group>
                    </ul>
                  </div>
                </div>
              </div>
              <span slot="footer" class="dialog-footer flex-row justify-center">
                <el-button @click="tagdialog = false" size="mini" class="margin-right-40 padding-lr-30">取 消</el-button>
                <el-button type="primary" @click="setTag" size="mini">保 存</el-button>
              </span>
            </el-dialog>

  </div>
</template>

<script>
import UploadForExcel from "@/components/datamanage/uploadForExcel";
import exportExcel  from "@/common/exportExcel";
export default {
  components: {
    UploadForExcel,
  },
  name: "DataUploadManage",
  data() {//数据
    return {
      active:0,
      activeByUpdata:0,
      updatafileName:'',
      tagdialog: false,
      productcheckList:[],
      url:{
        list:'/userdata/getUserDataField',//获取数据列表
        detailDataValue:'/userdata/getUserDataValue',//获取数据详情
        userDataTag:'/userDataTag/getTagsAndProducts',//获取标签和产品
        AddTag:'/userDataTag/addUserDataTag',//添加标签
        DelTag:'/userDataTag/deleteUserDataTag',//删除标签
        AddProduct:'/userDataTag/addProduct',//添加产品
        DelProduct:'/userDataTag/deleteProduct',//删除产品
        makeTag:'/userDataTag/makeTag',//设置标识
        delDataSet:'/userdata/delete',//删除上传数据
        download:'/userdata/download',
      },
      activeKey:"manage",
      dataSetByUpdatakeyWord:'',
      dataSetByUpdata:[],
      tagdict:{},
      tagsList:[],
      productsList:[],
      columns:[],
      setTableData:[],
      tableData:[],
      tableDataall:[],
      cellList:[],
      keyNum:0,
      tableDatadetail:[],
      selectedfile:"0",
      keywords:"",
      newtag:"",
      newproduct:"",
      currtablefieldId:0,
      selectedData:[],
      selectedTag:"",
      filenameList:[],
      pageload:false,
      freqscheck:'',
      dataunit:'',
      freqlist:[
        {
          title:'日度',
          value:'day'
        },
        {
          title:'周度',
          value:'week'
        },
        {
          title:'月度',
          value:'month'
        },
        {
          title:'年度',
          value:'year'
        },
      ]
    };
  },
  created() {//实例初始化之前的方法
    this.getDSList();
    this.getUserTag();
  },
  computed:{
   
  },
  mounted() {//实例挂载之前的方法

  },
  methods: {//vue实例的方法
    opentagDialog(){
      if(this.selectedData.length>0){
        this.freqscheck='';
        this.productcheckList=[];
        this.selectedTag='';
        this.dataunit='';
        this.tagdialog=true;
      }else{
        this.$message({message: "请先选中数据",type:'warning',offset: Math.ceil(document.documentElement.clientHeight/2.4)});
      }
        
    },
    changeselectedtable(data){
      this.selectedData=data.map(o=>{
        return o.fieldId
      });
    },
    add0(m){return m<10?'0'+m:m },
    dateTimeformatter(data){
      let dt = new Date(data)
      return dt.getFullYear() + '/' + this.add0((dt.getMonth() + 1)) + '/' + this.add0(dt.getDate());
    },
    initListData(){
      this.columns=[];
      this.tableData=[];
      this.tableDataall=[];
      this.setTableData=[];
      this.selectedData=[];
    },
    
    //查看用户的所有标签和关联产品
    getUserTag(){
      this.selectedTag="";
      this.$instance.get(this.url.userDataTag).then(res=>{
        if(res.data.code==0){
          this.tagsList=res.data.info['tags'];
          this.productsList=res.data.info['products'];
       
        }
      });
    },
    //查询所有的数据集
    getDSList(){
      this.pageload=true;
     
      let url=''
      if(this.activeKey=='manage'){
        url=this.url.list
      }else{
        url='/userdata/getUserDataFile'
      }
      this.$instance.get(url).then(res=>{
        this.initListData();
        if(res.data.info ){
            this.filenameList=res.data.info.items
            if(this.activeKey=='manage'){
              console.log('manage')
              if(res.data.info.dataList && res.data.info.dataList.length>0){
              this.tableDataall=res.data.info.dataList;
              this.tableData=res.data.info.dataList;
              //this.$refs.singleTable.setCurrentRow(this.tableData[0]);
              this.handleCurrentChange(this.tableData[0],0)
              }
            }else {
              
              if(res.data.info && res.data.info.length>0){
              this.dataSetByUpdataall=res.data.info;
              this.dataSetByUpdata=res.data.info;
              //this.$refs.fixUploadDrag.fileId=this.dataSetByUpdata[0].fileId;
              }
            }
        }
      
       
        this.pageload=false;
      }).catch(()=>{
        this.pageload=false;
      })
    },
   
    addTag(){
      this.$instance.post(this.url.AddTag,{tagname:this.newtag}).then(res=>{
        if(res.data.code==0){
         // this.$alert(res.data.msg, '提示', {confirmButtonText: '知道了', customClass:'custom-msgbox'});
          this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)});
          this.getUserTag();
        }
      });
    },
    delOneTag(tag){
      let that=this;
        that.$confirm("您确定要删除该项么？", '提示', {
          confirmButtonText: '确认', customClass:'custom-msgbox',
        }).then(() => {
          that.$instance.get(that.url.DelTag,{params:{tagId:tag}}).then(res=>{
            that.getUserTag();
            that.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
           
          });
        })
      
    },
    addproduct(){
      if(this.newproduct.trim().length>0){
        this.$instance.get(this.url.AddProduct+"?productName="+this.newproduct).then(res=>{
          if(res.data.code==0){
          // this.$alert(res.data.msg, '提示', {confirmButtonText: '知道了', customClass:'custom-msgbox'});
            this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)});
            this.getUserTag();
          }
        });
      }else{
        this.$message({message:'请填写产品名称',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      }
      
    },
    delproduct(tag){
      let that=this;
        that.$confirm("您确定要删除该项么？", '提示', {
          confirmButtonText: '确认', customClass:'custom-msgbox',
        }).then(() => {
          that.$instance.get(that.url.DelProduct,{params:{productId:tag}}).then(res=>{
            that.getUserTag();
            that.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
           
          });
        })
      
    },
    setTag(){
      let that=this;
      if(this.selectedTag=="" && this.productcheckList.length==0 && this.freqscheck=='' && this.dataunit.trim().length==0){
        this.$message({message: '请选择数据频率或数据类型或关联产品或单位！',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      }else{
        that.$instance.get(that.url.makeTag,{params:{fieldIds:this.selectedData.join(','),tagId:this.selectedTag,productIds:this.productcheckList.join(','),modelFreq:this.freqscheck,unit:this.dataunit.trim()}}).then(res=>{
            that.getDSList();
            that.tagdialog=false;
            that.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        });
      }
     
    },

   
    downloadData(){
      let item=this.dataSetByUpdata[this.activeByUpdata];
      exportExcel(this.$server+this.url.download,{fileId:item.id});
    },
  
    isEmptyObject(obj) {
      if (!obj || typeof obj !== 'object' || Array.isArray(obj))
        return false
      return !Object.keys(obj).length
    },
    deleteData(fid){//删除上传数据
        var _this=this;
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$instance.get(this.url.delDataSet, {params:{fieldId:fid}}).then(res=>{
            _this.getDSList();
            _this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          });
        })
     },
    searchdata(selecedfilename){
        if(selecedfilename!="0"){//不是全部
          this.tableData=this.tableDataall.filter(o=>{return o.fileName==selecedfilename});
        }else{
          this.tableData=this.tableDataall;
        }
        if(this.keywords.trim().length>0){
          this.tableData=this.tableData.filter(o=>{return o.fieldName.indexOf(this.keywords)>=0});
        }
    },
   searchupdate(){
    if(this.dataSetByUpdatakeyWord.trim().length>0){
          this.dataSetByUpdata=this.dataSetByUpdataall.filter(o=>{return o.filename.indexOf(this.dataSetByUpdatakeyWord)>=0});
        }else{
          this.dataSetByUpdata=this.dataSetByUpdataall;
        }
   },
    handleCurrentChange(val) {//切换详情数据
       this.getDetaildata(val.fieldId);
       this.$refs.singleTable.setCurrentRow(val);
    },
    getDetaildata(did){
      this.tableDatadetail=[];
      this.$instance.get(this.url.detailDataValue, {params:{dataId:did}}).then(res=>{
        if(res.data.info){
          this.tableDatadetail=res.data.info.map(o=>{
            return{
              datadate:  this.$dateFormat(o.datadate,'yyyy-MM-dd'),
              value: o.value
            }
          });
        }
        
      });
    },
    tabclick(){

      this.$nextTick(()=>{
        this.getDSList();
      })
       
       
    },
    getDataSetByUpdata(item,i){
      this.activeByUpdata=i;
      this.updatafileName=item.filename;
    },
  },

}
</script>
<style lang="scss">
.tagmanage{
  .el-dialog__header{
    padding-bottom: 5px;
  }
  .el-dialog__body{
    padding-top:0 ;
    padding-bottom: 10px;
    .leftbox{
      box-sizing: border-box;
      width: 280px;
   
    }
    ul{
        height: 335px;
        overflow-y: auto;
      }
  }
  .dialog-footer{
    .el-button{
        width: 120px;
    }
  }
}
.activeClass{
    background-color: rgba(70,167,245, 0.15);
}
.el-upload{
    width: 100%;
    height: 100%;
    .el-upload-dragger{
      width: 100%;
      height: 100%;
      border: none;
    }
}
.scrolltable{
  .is-scrolling-none{
      height: calc(100vh - 300px);
      overflow-y: auto;
  }
}

</style>

